button {
    border-style: solid;
}
button:focus{
    outline: none;
}

.btn-salmon {
    color: #F87F71;
    background-color: #FEECEA;
    border-color: #FEECEA;
}
.btn-salmon:hover {
    color: #F87F71;
    background-color: #FFD6D1;
    border-color: #FFD6D1;
}
.btn-salmon:focus, .btn-salmon.focus {
    color: #F87F71;
    background-color: #FFD6D1;
    border-color: #FFD6D1;
    box-shadow: 0 0 0 0.2rem rgba(249, 146, 134, 0.5);
}
.btn-salmon.disabled, .btn-salmon:disabled {
    color: #F87F71;
    background-color: #FEECEA;
    border-color: #FEECEA;
}
.btn-salmon:not(:disabled):not(.disabled):active, .btn-salmon:not(:disabled):not(.disabled).active, .show > .btn-salmon.dropdown-toggle {
    color: #F87F71;
    background-color: #FFD6D1;
    border-color: #FFD6D1;
}
.btn-salmon:not(:disabled):not(.disabled):active:focus, .btn-salmon:not(:disabled):not(.disabled).active:focus, .show > .btn-salmon.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(249, 146, 134, 0.5);
}

.card-header-rounded {
    border-radius: calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px) !important;
}

.bigger-1-5
{
    /* Double-sized Checkboxes */
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
}

.btn-gray {
    color: #ffffff;
    background-color: #808E97;
    border-color: #808E97;
}
.btn-gray:hover {
    color: #ffffff;
    background-color: #89959d;
    border-color: #89959d;
}
.btn-gray:focus, .btn-gray.focus {
    color: #ffffff;
    background-color: #89959d;
    border-color: #89959d;
    box-shadow: 0 0 0 0.2rem rgba(227, 223, 221, 0.5);
}
.btn-gray.disabled, .btn-gray:disabled {
    color: #ffffff;
    background-color: #808E97;
    border-color: #808E97;
}
.btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active, .show > .btn-gray.dropdown-toggle {
    color: #ffffff;
    background-color: #89959d;
    border-color: #89959d;
}
.btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-gray.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 230, 230, 0.5);
}

.card-header-rounded {
    border-radius: calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px) !important;
}

.bigger-1-5
{
    /* Double-sized Checkboxes */
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
}

.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}
