.table {
    font-size: 0.75rem;
}

.page-link{
    border-color: #858796 !important;
}

.filter-card{
    margin-bottom: 5px;
    background-color: #F8F9FC !important;
}

.filter-card-subdata{
     margin-bottom: 5px;
     background-color: #F8F9FC !important;
 }

.filter-card .card-body{
    padding: 10px !important;
}

.filter-card-subdata .card-body{
    padding: 10px !important;
    background: #E0E8FF;
}

.swagger-ui .response-col_status {
    width: 10% !important;
}

.swagger-ui .parameters-col_name{
    width: 20% !important;
}

.modal-body{
    font-size: 0.8rem;
}


.dt-modal .removeClass {
    margin-left: 5px;
}


.dt-modal .badge {
    padding: 0.5em 0.5em !important;
    margin: 2px !important;
    font-size: 100% !important;
}

.table .badge {
    padding: 0.5em 0.5em !important;
    font-size: 100% !important;
}


.dt-modal .tagInputClass {
    margin-top: 10px;
}

.screen-title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 1.5rem;}

.disable-selection {
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

.disable-selection {
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

.hideElement {
    display: none !important;
}

.toastify {
    z-index: 2147483647 !important;
}

.suggestionsClass {
    margin-top: 10px;
}

.table thead th {
    border: none !important;
    background-color: #F6F9F9;
    text-transform: uppercase !important;
}

.visit-head .badge {
    font-size: 80% !important;
    margin: 1px;
}